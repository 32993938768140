import React, { Component } from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';

import Footer from '../components/footer';
import Layout from '../components/layout';
import NavSection from '../components/nav-section';

class Contacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      venueDate: '',
      venueTime: '',
    };
  }

  render() {
    const navMenus = get(this, 'props.data.allContentfulNavigation.edges');
    const {
    contactHeader,
    companyLocation,
    companyPhoneNumber,
    companyEmail,
    companyFacebookLink,
    companyInstagramLink,
    openingHours,
    closingHours,
    mapsImage,
    googleMapLink,
    } = get(this, 'props.data.allContentfulPageContactUs.edges')[0].node;

    const footer = get(this, 'props.data.allContentfulFooter.edges')

    return (
      <Layout>
        <NavSection navMenus={navMenus} navTheme="nav-white" />
        <Helmet title="Contacts" />

        <section id="contactSection" className="pb-4">
          <div className="container pt70 pb-4 venue-form">
            <div className="row justify-content-between">
              <div className="col-lg-6 mb-5">
                <h1>{contactHeader}</h1>
                <ul>
                  <li>{companyLocation}</li>
                  <li>
                    <a href={`tel:${companyPhoneNumber}`}>
                      {companyPhoneNumber}
                    </a>
                  </li>
                  <li>
                    <a href={`mailto:${companyEmail}`}>
                      {companyEmail}
                    </a>
                  </li>
                  <li className="pt-3">
                    <a href={companyFacebookLink}>
                    <span className="fa fa-facebook-square"></span> Facebook
                    </a>
                  </li>
                  <li>
                    <a href={companyInstagramLink}>
                    <span className="fa fa-instagram"></span> Instagram
                    </a>
                  </li>
                  <li className="pt-3">
                    {openingHours.openingHours}
                  </li>
                  <li>{closingHours}</li>
                </ul>
              </div>
              <div className="col-lg-5 mb-5">
                <form
                  className="default-form"
                  action="https://gmail.us5.list-manage.com/subscribe/post?u=8888c103642d087afb1f42fef&amp;id=72e829b037&SIGNUP=Contact%20Page"
                  method="post"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="FNAME"
                        placeholder="First name"
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <input type="text" name="LNAME" placeholder="Last name" />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="PHONE"
                        placeholder="Contact no."
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="email"
                        name="EMAIL"
                        placeholder="Email address"
                        required
                      />
                    </div>
                    <div className="col-md-12">
                      <select name="INQUIRY" required>
                        <option value="" selected disabled>
                          Inquiry type
                        </option>
                        <option value="Admission">Admission</option>
                        <option value="Weddings">Weddings</option>
                        <option value="Photo Shoots">Photo and Video Shoots</option>
                        <option value="Other Private Events">
                          Other Private Events
                        </option>
                      </select>
                      <img src="/images/arrow.png" className="select-arrow" />
                    </div>
                    <div className="col-md-12">
                      <textarea
                        name="MESSAGE"
                        rows="3"
                        placeholder="Inquiry / comment / suggestion"
                      ></textarea>
                    </div>
                    <div className="col-md-12">
                      <div className="row align-items-center justify-content-between">
                        <div className="col-md-8 mt-3 mb-2">
                          <label className="checkbox-label">
                            <input type="checkbox" required /> Subscribe to our
                            newsletter
                          </label>
                        </div>
                        <div className="col-md-4 mt-3 mb-2">
                          <button className="btn w-100" type="submit">
                            submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section
          className="section-maps"
          style={{ backgroundImage: `url(${mapsImage.file.url})` }}
        >
          <div className="contact-maps-container">
            <div className="container contact-maps">
              {/* <a
                href="https://www.google.com.ph/maps/place/Palacio+de+Memoria/@14.5199979,120.9921265,17z/data=!3m1!4b1!4m5!3m4!1s0x3397cf8b64bc0417:0xcab4fb96764922f5!8m2!3d14.5199979!4d120.9943152"
                target="_blank"
              > */}
              <a
                href={googleMapLink.googleMapLink}
                target="_blank"
              >
                <button className="btn">View on Maps</button>
              </a>
            </div>
          </div>
        </section>
        <Footer 
          footer={footer}
        />
      </Layout>
    );
  }
}

export default Contacts;

export const pageQuery = graphql`
  query ContactsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulNavigation(sort: { fields: createdAt }) {
      edges {
        node {
          id
          slug
          pageTitle
          submenu
        }
      }
    }
    allContentfulPageContactUs {
      edges {
        node {
          contactHeader
          companyLocation
          companyPhoneNumber
          companyEmail
          companyFacebookLink
          companyInstagramLink
          openingHours {
            openingHours
          }
          closingHours
          mapsImage {
            file {
              url
            }
          }
          googleMapLink {
            googleMapLink
          }
        }
      }
    }
    allContentfulFooter {
      edges {
        node {
          facebookLink
          footerAddress
          footerEmail
          footerHeadline
          footerImage {
            file {
              url
            }
          }
          footerLandline
          footerMobileNumber
          footerOpeningDates
          footerOpeningHours
          instagramLink
          section
        }
      }
    }
  }
`;
